<template>
  <div>
    <div class="text-h6 font-weight-medium mb-4">{{ $t("payment.title") }}</div>
    <v-card class="rounded-md mb-4">
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-btn
          depressed
          @click="refreshItems()"
          :loading="loading.refresh"
          class="mr-2 mb-2"
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $t("btn.refresh") }}
        </v-btn>
        <v-btn
          depressed
          @click="filterItems()"
          class="mr-2 mb-2"
          :loading="loading.filter"
        >
          <v-icon left>mdi-filter-variant</v-icon>
          {{ $t("btn.filter") }}
        </v-btn>
        <v-btn
          @click="exportPayments()"
          :loading="loading.export"
          :disabled="loading.list"
          class="mb-2"
          depressed
        >
          <v-icon small left>mdi-file-excel-box-outline</v-icon>
          {{ $t("btn.export") }}
        </v-btn>
      </div>
    </v-card>
    <v-card>
      <v-card-title>
        <div class="font-weight-bold">{{ $t("payment.list.title") }}</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        sort-by="id"
        sort-desc
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="item in items">
              <v-hover :key="item.id" v-slot="{ hover }">
                <tr>
                  <td style="min-width: 160px;">
                    <span>{{
                      $moment(item.created).format("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                  </td>
                  <td>
                    <v-chip
                      color="success"
                      class="table-chips"
                      dark
                      v-if="item.status === 'SUCCESS'"
                      >{{ $t("payment.fields.status.SUCCESS") }}</v-chip
                    >
                    <v-chip
                      color="danger"
                      class="table-chips"
                      dark
                      v-else-if="
                        item.status === 'CANCEL' || item.status === 'ERROR'
                      "
                      >{{ $t("payment.fields.status.CANCEL") }}</v-chip
                    >
                    <v-chip
                      color="blue accent-4"
                      class="table-chips"
                      dark
                      v-else-if="
                        item.status === 'NEW' || item.status === 'PENDING'
                      "
                      >{{ $t("payment.fields.status.PENDING") }}</v-chip
                    >
                    <v-chip
                      color="amber accent-4"
                      class="table-chips"
                      dark
                      v-else-if="item.status === 'TIMEOUT'"
                      >{{ $t("payment.fields.status.TIMEOUT") }}</v-chip
                    >
                  </td>
                  <td>{{ getPrice(item.price) }}</td>
                  <td>
                    <v-chip
                      color="paypal"
                      class="table-chips"
                      dark
                      v-if="item.type === 'PAYPAL'"
                      >PAYPAL</v-chip
                    >
                    <v-chip
                      color="momo"
                      class="table-chips"
                      dark
                      v-if="item.type === 'MOMO'"
                      >MOMO</v-chip
                    >
                    <v-chip
                      color="om"
                      class="table-chips"
                      dark
                      v-if="item.type === 'OM'"
                      >OM</v-chip
                    >
                    <v-chip
                      color="eum"
                      class="table-chips"
                      dark
                      v-if="item.type === 'EUM'"
                      >EUM</v-chip
                    >
                  </td>
                  <td>{{ item.accountNumber }}</td>
                  <td>{{ item.pack.title }}</td>
                  <td style="min-width: 120px" class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="displayPayment(item)"
                          v-show="hover || $vuetify.breakpoint.mdAndDown"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                        >
                          <v-icon color="primary">mdi-eye-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("payment.btn.show.title") }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <payment-view :dialog="dialog.view" :payment="payment"></payment-view>
    <payment-filter
      :dialog="dialog.filter"
      @filter="applyFilter"
    ></payment-filter>
  </div>
</template>

<script>
import PaymentFilter from "./filter";
import PaymentView from "./view";
import PackFormatterMixin from "./../../mixins/packs/formatter";
import ListMixin from "./../../mixins/commons/list";
export default {
  mixins: [ListMixin, PackFormatterMixin],
  data: () => ({
    path: "/api/payments",
    dialog: {
      view: {
        display: false,
      },
      filter: {
        display: false,
      },
    },
    payment: {},
  }),
  metaInfo() {
    return {
      title: this.$t("payment.title"),
    };
  },
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$t("payment.fields.created.title"),
          align: "start",
          sortable: true,
          value: "created",
          width: 160,
        },
        {
          text: this.$t("payment.fields.status.title"),
          align: "start",
          sortable: false,
          value: "status",
          width: 120,
        },
        {
          text: this.$t("payment.fields.price.title"),
          align: "start",
          sortable: false,
          value: "price",
          width: 120,
        },
        {
          text: this.$t("payment.fields.accountType.title"),
          align: "start",
          sortable: false,
          value: "accountType",
          width: 120,
        },
        {
          text: this.$t("payment.fields.accountNumber.title"),
          align: "start",
          sortable: false,
          value: "accountNumber",
          width: 120,
        },
        {
          text: this.$t("payment.fields.pack.title"),
          align: "start",
          sortable: false,
          value: "pack.title",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: null,
          width: 120,
        },
      ];
    },
    displayPayment(item) {
      this.payment = item;
      this.dialog.view.display = true;
    },
    filterItems() {
      this.dialog.filter.display = true;
    },
    async exportPayments() {
      try {
        const response = await this.exportItems("/api/payments/export");
        if (!response) return;
        const url =
          (process.env.VUE_APP_BASE_API || "") + "/" + response.data.filename;
        this.$utils.downloadUri(url);
      } catch (error) {
        // empty
        console.log(error);
      }
    },
  },
  components: {
    PaymentView,
    PaymentFilter,
  },
};
</script>
