<template>
  <div>
    <payment-list></payment-list>
  </div>
</template>

<script>
import PaymentList from './../../components/payments/list';
export default {
  data: () => ({
  }),
  components: {
    PaymentList
  }
}
</script>