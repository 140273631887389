<template>
  <v-dialog v-model="dialog.display" max-width="550">
    <v-card>
      <v-card-title>
        <div class="text-subtitle-1">
          <span class="secondary-font font-weight-bold">
            {{
            $t("payment.display.title")
            }}
          </span>
        </div>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container fluid grid-list-md v-if="!_.isEmpty(payment)">
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-calendar-blank</v-icon>
                <span class="text-body-1 font-weight-bold">
                  {{
                  $t("payment.fields.created.title")
                  }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2">
                    <span class="font-weight-bold">
                      {{
                      $moment(payment.created).format("YYYY-MM-DD HH:mm:ss")
                      }}
                    </span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-cash</v-icon>
                <span class="text-body-1 font-weight-bold">
                  {{
                  $t("payment.fields.status.title")
                  }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip
                  color="success"
                  class="table-chips"
                  dark
                  v-if="payment.status === 'SUCCESS'"
                >{{ $t('payment.fields.status.SUCCESS') }}</v-chip>
                <v-chip
                  color="danger"
                  class="table-chips"
                  dark
                  v-else-if="payment.status === 'CANCEL' || item.status === 'ERROR'"
                >{{ $t('payment.fields.status.CANCEL') }}</v-chip>
                <v-chip
                  color="blue accent-4"
                  class="table-chips"
                  dark
                  v-else-if="payment.status === 'NEW' || item.status === 'PENDING'"
                >{{ $t('payment.fields.status.PENDING') }}</v-chip>
                <v-chip
                  color="amber accent-4"
                  class="table-chips"
                  dark
                  v-else-if="payment.status === 'TIMEOUT'"
                >{{ $t('payment.fields.status.TIMEOUT') }}</v-chip>
              </div>
            </v-col>
          </v-row>
          <template v-if="payment.status === 'SUCCESS'">
            <v-divider></v-divider>
            <v-row align="center" justify="center" class="py-2">
              <v-col cols="12" sm="4">
                <div>
                  <v-icon class="mr-2 pb-1">mdi-identifier</v-icon>
                  <span class="text-body-1 font-weight-bold">
                    {{
                    $t("payment.fields.reference.title")
                    }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="text-right">
                  <v-chip color="fourth" class="table-chips mr-0">
                    <span class="text-body-2">
                      <span class="font-weight-bold">
                        {{
                        payment.reference
                        }}
                      </span>
                    </span>
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </template>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-identifier</v-icon>
                <span class="text-body-1 font-weight-bold">
                  {{
                  $t("payment.fields.invoiceId.title")
                  }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2">
                    <span class="font-weight-bold">
                      {{
                      payment.invoiceId
                      }}
                    </span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-package-variant</v-icon>
                <span class="text-body-1 font-weight-bold">
                  {{
                  $t("payment.fields.pack.title")
                  }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2">
                    <span class="font-weight-bold">
                      {{
                      payment.pack.title
                      }}
                    </span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-cash</v-icon>
                <span class="text-body-1 font-weight-bold">
                  {{
                  $t("payment.fields.price.title")
                  }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2">
                    <span class="font-weight-bold">{{ getPrice(payment.price) }}</span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-counter</v-icon>
                <span class="text-body-1 font-weight-bold">
                  {{
                  $t("payment.fields.quantity.title2", { unit: payment.pack.unit.toUpperCase() })
                  }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2">
                    <span class="font-weight-bold">{{ $utils.pad(payment.quantity, 2) }} {{ }}</span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-bank-outline</v-icon>
                <span class="text-body-1 font-weight-bold">
                  {{
                  $t("payment.fields.accountType.title")
                  }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="paypal" class="table-chips" dark v-if="payment.type === 'PAYPAL'">PAYPAL</v-chip>
                <v-chip color="momo" class="table-chips" dark v-if="payment.type === 'MOMO'">MOMO</v-chip>
                <v-chip color="om" class="table-chips" dark v-if="payment.type === 'OM'">OM</v-chip>
                <v-chip color="eum" class="table-chips" dark v-if="payment.type === 'EUM'">EUM</v-chip>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" justify="center" class="py-2">
            <v-col cols="12" sm="8">
              <div>
                <v-icon class="mr-2 pb-1">mdi-cash</v-icon>
                <span class="text-body-1 font-weight-bold">
                  {{
                  $t("payment.fields.accountNumber.title")
                  }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="text-right">
                <v-chip color="fourth" class="table-chips mr-0">
                  <span class="text-body-2">
                    <span class="font-weight-bold">{{ payment.accountNumber }}</span>
                  </span>
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PackFormatterMixin from "./../../mixins/packs/formatter";
export default {
  mixins: [PackFormatterMixin],
  props: {
    dialog: {
      type: Object,
      default: function() {
        return {
          display: false
        };
      }
    },
    payment: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data: () => ({})
};
</script>