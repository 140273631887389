<template>
  <v-dialog v-model="dialog.display" max-width="500">
    <v-card>
      <v-card-title>
        <div class="text-subtitle-1">
          <span class="secondary-font font-weight-bold">
            {{ $t("payment.filter.title") }}
          </span>
        </div>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-7 pt-3">
        <v-dialog
          ref="modalDaterange"
          v-model="modal.daterange"
          :return-value.sync="daterange.value"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="daterange.formatted"
              :label="$t('payment.fields.daterange.title')"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="daterange.value" range scrollable>
            <v-spacer></v-spacer>
            <v-btn text @click="clearDaterange()">
              {{ $t("btn.clear") }}
            </v-btn>
            <v-btn
              text
              color="primary"
              class="font-weight-bold"
              @click="applyDaterange()"
            >
              {{ $t("btn.apply") }}
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-autocomplete
          class="font-weight-bold"
          v-model="filters.userId.value"
          :label="$t('user.fields.email.title')"
          clearable
          :items="users"
          :loading="search.user.loading"
          v-if="$auth.check(['ROLE_ADMIN', 'ROLE_COMMERCIAL'])"
          :hint="$t('user.fields.email.filter')"
          item-value="id"
          item-text="email"
          persistent-hint
          hide-no-data
          :search-input.sync="search.user.value"
        ></v-autocomplete>
        <v-select
          class="font-weight-bold"
          v-model="filters.status.value"
          :label="$t('payment.fields.status.title')"
          clearable
          append-icon="mdi-checkbox-blank-circle-outline"
          :items="statuses"
          :item-text="(item) => $t(item.text)"
        ></v-select>
        <v-text-field
          class="font-weight-bold"
          v-model="filters.invoiceId.value"
          :label="$t('payment.fields.invoiceId.title')"
          clearable
          append-icon="mdi-identifier"
          autocomplete="off"
        ></v-text-field>
        <v-text-field
          class="font-weight-bold"
          v-model="filters.reference.value"
          :label="$t('payment.fields.reference.title')"
          clearable
          append-icon="mdi-text-box-outline"
          autocomplete="off"
        ></v-text-field>
        <v-select
          class="font-weight-bold"
          v-model="filters.type.value"
          :label="$t('payment.fields.accountType.title')"
          clearable
          append-icon="mdi-bank-outline"
          :items="accountTypes"
        ></v-select>
        <v-text-field
          class="font-weight-bold"
          v-model="filters.accountNumber.value"
          :label="$t('payment.fields.accountNumber.title2')"
          clearable
          append-icon="mdi-text-box-outline"
          autocomplete="off"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="mt-0 px-5">
        <v-spacer></v-spacer>
        <v-btn text @click="reset()">{{ $t("btn.reset") }}</v-btn>
        <v-btn
          text
          color="primary"
          class="font-weight-bold"
          @click="filter()"
          >{{ $t("btn.filter") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    dialog: {
      type: Object,
    },
  },
  data: () => ({
    search: {
      user: { value: null, loading: false, searching: null },
    },
    modal: {
      daterange: false,
    },
    daterange: { formatted: null, value: [] },
    users: [],
    filters: {
      invoiceId: {
        operator: "$eq",
        value: null,
      },
      status: {
        operator: "$eq",
        value: null,
      },
      reference: {
        operator: "$eq",
        value: null,
      },
      type: {
        operator: "$eq",
        value: null,
      },
      accountNumber: {
        operator: "$eq",
        value: null,
      },
      userId: {
        value: null,
        operator: "$eq",
      },
      created_gte: {
        value: null,
        operator: "$gte",
        field: "created",
      },
      created_lte: {
        value: null,
        operator: "$lte",
        field: "created",
      },
    },
    statuses: [
      { value: "PENDING", text: "payment.fields.status.PENDING" },
      { value: "SUCCESS", text: "payment.fields.status.SUCCESS" },
      { value: "CANCEL", text: "payment.fields.status.CANCEL" },
      { value: "ERROR", text: "payment.fields.status.ERROR" },
      { value: "TIMEOUT", text: "payment.fields.status.TIMEOUT" },
    ],
  }),
  methods: {
    applyDaterange() {
      const daterange = this.$utils.parseDaterange(this.daterange.value);
      if (!daterange) {
        this.daterange.formatted = null;
        return;
      }
      this.$refs.modalDaterange.save(daterange);
      this.daterange.formatted = `${daterange[0]} - ${daterange[1]}`;
    },
    clearDaterange() {
      this.daterange = [];
      this.modal.daterange = false;
    },
    filter() {
      if (this.daterange.value.length > 1) {
        const daterange = this.$utils.parseDaterange(this.daterange.value);
        this.filters.created_gte.value = daterange[0] + " 00:00:00";
        this.filters.created_lte.value = daterange[1] + " 23:59:59";
      } else {
        this.filters.created_gte.value = null;
        this.filters.created_lte.value = null;
      }
      this.$emit("filter", this.filters);
    },
    reset() {
      this.$emit("filter", {});
      this.trackingStatus = null;
      this.daterange.value = [];
      this.daterange.formatted = null;
      for (const prop in this.filters) {
        this.filters[prop].value = null;
      }
    },
    ...mapActions({ request: "request" }),
  },
  watch: {
    "search.user.value"(val) {
      if (val < 1) return;

      if (this.search.user.searching) clearTimeout(this.search.user.searching);

      if (this.users.findIndex((u) => u.email === val) > -1) return;

      this.search.user.loading = true;

      this.search.user.searching = setTimeout(async () => {
        try {
          const response = await this.request({
            url: `api/users?filter=email||$contL||${val}&filter=role||$eq||ROLE_CLIENT`,
          });
          this.users = response.data.data;
        } catch (error) {
          // empty
        }
        this.search.user.loading = false;
      }, 500);
    },
  },

  computed: {
    ...mapGetters({ accountTypes: "operators" }),
  },
};
</script>
